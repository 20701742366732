import React, { createRef, useState, RefObject, DragEvent, ChangeEvent } from 'react';
import clsx from 'clsx';
import { FieldProps } from 'formik';
import { byteSizeToHumanReadable } from '@utils';
import { saveFileListToFormikField, combineFileLists, createSingletonFileList } from './utils';
import { DocumentIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface IProps extends FieldProps {
    allowMultipleFiles?: boolean;
}

export default function SimpleFileInput(props: IProps) {
    const { field, form, allowMultipleFiles } = props;
    const fileList = typeof field.value === 'object' ? (field.value as FileList) : null;
    const fileInputRef = createRef() as RefObject<HTMLInputElement>;
    const [isDrag, setIsDrag] = useState<boolean>(false);

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        setIsDrag(false);
        updateFiles(event.dataTransfer.files);
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        setIsDrag(true);
        event.dataTransfer.dropEffect = 'copy';
    };

    const updateFiles = (selectedFiles: FileList) => {
        if (!selectedFiles || selectedFiles.length === 0) {
            return;
        }
        const filesToSave: FileList = allowMultipleFiles
            ? combineFileLists([selectedFiles, fileList])
            : createSingletonFileList(selectedFiles[0]); // Ensure that only one file is selcted to support drag and drop
        saveFileListToFormikField(filesToSave, field, form, allowMultipleFiles);
        // Clear the hidden input field so next onChange will work for same file
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleOpenFileSelector = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const removeFile = (fileName: string) => {
        if (!fileList) return;
        const dataTransfer = new DataTransfer();
        Array.from(fileList).forEach((file) => {
            if (file.name !== fileName) {
                dataTransfer.items.add(file);
            }
        });
        saveFileListToFormikField(dataTransfer.files, field, form, allowMultipleFiles);
    };

    return (
        <>
            <div
                className={clsx('flex-auto mr-1.5', { 'border-2 rounded-md border-dotted border-emerald-500': isDrag })}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={() => setIsDrag(false)}
            >
                <div className='flex flex-row space-x-2'>
                    <button
                        className='py-1 min-w-36 max-h-7 rounded bg-white text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 flex justify-center hover:bg-gray-200'
                        onClick={handleOpenFileSelector}
                    >
                        <DocumentIcon className='h-4 w-4 my-auto mr-1' />
                        Select file{allowMultipleFiles && 's'}
                    </button>
                    <button
                        className='py-1 min-w-36 max-h-7 rounded bg-white text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 flex justify-center hover:bg-gray-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
                        onClick={() => saveFileListToFormikField(null, field, form, allowMultipleFiles)}
                        disabled={!fileList || !fileList?.length}
                    >
                        <TrashIcon className='h-4 w-4 my-auto mr-1' />
                        Clear file{allowMultipleFiles && 's'}
                    </button>
                </div>
                <div className='mt-2'>
                    <ul className='list-none space-y-3'>
                        {Array.from(fileList ?? []).map((file) => (
                            <div className='flex flex-row space-x-2' key={file.name}>
                                <button onClick={() => removeFile(file.name)}>
                                    <XMarkIcon className='size-6 my-auto rounded-md hover:bg-gray-100 hover:text-red-700' />
                                </button>
                                <li>
                                    {file.name} - {byteSizeToHumanReadable(file.size)}
                                </li>
                            </div>
                        ))}
                    </ul>
                </div>
            </div>
            <input
                alt='Select Text File'
                hidden={true}
                multiple={allowMultipleFiles}
                name='text-file-input'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateFiles(event.currentTarget.files);
                }}
                ref={fileInputRef}
                type='file'
            />
        </>
    );
}
