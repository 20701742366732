import React from 'react';
import RenderSettings from './RenderSettings';
import { Callout, Intent, Tooltip } from '@blueprintjs/core';
import { IRemoteHost, ISetting } from '@biolibtech/biolib-js';
import { Form, Formik } from 'formik';
import { ISettingValues } from './types';
import { Button } from '@components/base/button';
import { PlayIcon } from '@heroicons/react/16/solid';
import {
    getFlatSettingsDict,
    getSettingValues,
    validateSettings
} from '@features/appRun/components/tabs/InputTab/utils';

interface IProps {
    settings: ISetting[];
    onSubmit: (values: ISettingValues) => void;
    appRemoteHosts: IRemoteHost[];
    autoSubmitOnceValid: boolean;
}

export default function InputTab(props: IProps) {
    const { settings, appRemoteHosts } = props;
    const [settingsDict] = React.useState(getFlatSettingsDict(settings));
    return (
        <Formik
            initialValues={getSettingValues(settings)}
            onSubmit={props.onSubmit}
            validate={(values: ISettingValues) => validateSettings(settingsDict, values)}
        >
            {({ isValid, submitCount, submitForm }) => (
                <Form method='POST' className='content' onSubmit={(event) => event.preventDefault()}>
                    <div className='p-6'>
                        <RenderSettings settings={settings} autoSubmitOnceValid={props.autoSubmitOnceValid}/>
                        {settings.length === 0 ?
                            <p style={{ marginBottom: '25px' }}>This app does not take any inputs</p> : null
                        }
                        {settings.length > 0 && appRemoteHosts.length > 0 ?
                            <Callout intent={Intent.PRIMARY} title='External Communication'>
                                The application may access the following hosts during runtime, including sending
                                and
                                receiving data. Make sure you trust the below web servers as your input data may
                                be
                                revealed to them.
                                <ul style={{ listStyleType: 'disc' }}>
                                    {appRemoteHosts.map(({ hostname }, index) => <li
                                        key={index}>{hostname}</li>)}
                                </ul>
                            </Callout>
                            : null
                        }
                        <footer className='flex flex-row justify-around items-center pt-6'>
                            <Tooltip
                                content='Please fix errors above'
                                disabled={!(!isValid && submitCount > 0)}
                            >
                                <Button
                                    color='emerald'
                                    disabled={!isValid && submitCount > 0}
                                    onClick={submitForm}
                                >
                                    Run <PlayIcon/>
                                </Button>
                            </Tooltip>
                        </footer>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
